@import "./_fonts.scss";

.answers-statistics-container {
    display: flex;
    flex-direction: column;

    .answers-statistics-first-part {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .the-most-answers-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 3vh;
            overflow-y: scroll;
            height: 50vh;
            width: 34vw;
            padding: 4vh 2vw;
            padding-bottom: 1vh;
            
            .the-most-answers-data{
                display: flex;
                flex-direction: row-reverse;
                // margin-bottom: 3vh;
                width: 100%;
                align-items: center;
                .the-most-answer-number{
                    height: 5.5vh;
                    width: 8.7%;
                    margin-left: 2vw;
                    text-align: center;
                    padding-top: 0.4vh;
                    color: white;
                    font-family: Heebo-Medium;
                    border-radius: 5px;
                    font-size: 156%;
                }
                .the-most-answer-text{
                    direction:rtl;
                    overflow-wrap: break-word;
                    font-family: Heebo-Regular;
                    width: 90%;
                    font-size: 96%;
                }
            }
        }

    }
    .answers-statistics-second-part{
        display: flex;
        flex-direction: row-reverse;
        margin-top: 3vh;
        justify-content: space-between;
        .high-risk-answers-container {
            display: flex;
            flex-direction: column;
            width: 90%;
            .high-risk-answers-data{
                display: flex;
                flex-direction: row-reverse;
                margin-bottom: 3vh;
                width: 100%;
                align-items: center;
                margin-left: 3vw;
                .high-risk-answer-number{
                    height: 5.5vh;
                    width: 8.7%;
                    margin-left: 2vw;
                    text-align: center;
                    padding-top: 0.4vh;
                    color: white;
                    font-family: Heebo-Medium;
                    border-radius: 5px;
                    font-size: 156%;
                    background-color: #00B4A9;
                }
                .high-risk-answer-text{
                    direction:rtl;
                    overflow-wrap: break-word;
                    font-family: Heebo-Regular;
                    width: 90%;
                    font-size: 96%;
                }
            }
        }
    }
}