@import "./_fonts.scss";
.cursor-pointer path {
  cursor: pointer;
}

.generic-pie-chart {

    ul.recharts-default-legend {
        width: 20vw;
    }

    .recharts-legend-item-text {
        margin-right: 0.5vw;
        font-family: Heebo-regular;
    }

    .custom-tooltip {
        direction: rtl;
        font-family: Heebo-regular;

        .tooltip-title {
            font-size: 1vw;
        }

        .tooltip-percentage {
            font-size: 1vw;
            font-weight: 400;
        }

        .tooltip-content {
            font-size: 1vw;
            font-weight: 600;
            opacity: 60%;
        }
    }

    .label-text {
        font-family: Heebo-regular;
    }

    
    

}
.bottom {
    ul.recharts-default-legend {
        width: 20vw;
    }

    .recharts-legend-item-text {
        margin-right: 0.5vw;
        font-family: Heebo-regular;
    }
    ul.recharts-default-legend {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 22vw;
    }
}