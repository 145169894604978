@import "./_fonts.scss";

.general-statistics-container {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .sorted-info-container {
            display: flex;
            flex-direction: row;
            font-family: Heebo-Regular;
            direction: rtl;
            text-align: center;
            margin-right: 0.8vw;
            margin-top: 1vh;

            .sorted-info {
                height: 3.5vh;
                background-color: white;
                border-radius: 4px;
                padding: 0 0.8vw;
                cursor: pointer;
                margin-right: 0.8vw;

                .close {
                    width: 0.8vw;
                    margin-right: 0.6vw;
                    opacity: 0.7;
                }
            }
        }
    }


    .general-statistics-first-part {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        .info-text {
            font-family: Heebo-Medium;
            color: #448EAB;
            font-size: 8vh;
            text-align: center;

            .when-info-sorted-text {
            font-family: Heebo-Regular;
                font-size: 2.5vh;
                color:black;
                opacity: 0.45;
                margin-top: -4vh;
            }
        }

        .time-container {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            align-items: flex-end;
            padding-right: 1vw;


            .minutes-text {
                font-family: Heebo-Medium;
                color: #448EAB;
                font-size: 2.5vh;
                margin-bottom: 2vh;
                margin-right: 0.5vw;
            }

        }

    }

    .general-statistics-second-part {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 3vh;
    }

    .general-statistics-third-part {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 3vh;

        .responsive-container-horizontal-graph {
            padding-right: 37vw;
            .cursor-pointer path {
                cursor: pointer;
              }
              .custom-tooltip {
                direction: rtl;
                font-family: Heebo-regular;
                background-color: white;
                width:14vw;
                height: 17vh;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
        
                .tooltip-title {
                    font-size: 1vw;
                }
        
                .tooltip-percentage {
                    font-size: 1vw;
                    font-weight: 400;
                }
        
                .tooltip-content {
                    font-size: 1vw;
                    font-weight: 600;
                    opacity: 60%;
                }
            }              

            .recharts-surface {
                overflow: visible;
                width: 35vw;
                object-fit: cover;
                padding-right: 3VW;
            }

            text:not(svg) {
                font-family: Heebo-medium;
            }
        }

        .filler_custom-tooltip {
            direction: rtl;
            background-color: white;
            box-shadow: 0px 3px 6px #00000029;
            width: 24vw;
            height: 27vh;
            padding: 1vh 1vw;
            .filler-prof-container {
                display: grid;
                grid-template-columns: repeat(2, 2fr);
                margin-right: 1vw;
                margin-left: 1vw;
                height: 15vh;

                .filler-name-label {
                    font-family: Heebo-medium;
                    font-size: 1.9vh;
                    display: flex;
                    margin-left: 1vw;


                }

                .filler-count {
                    margin-left: 0.8vw;
                    color: #00638A;
                    font-family: Heebo-medium;
                    font-size: 2.1vh;
                }
            }
        }

    }

    .general-statistics-forth-part {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 3vh;

        .tooltip-content {
            font-size: 1vw;
            font-weight: 400;
            overflow-wrap: normal;
            font-family: Heebo-Regular;
        }

    }

}