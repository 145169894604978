@mixin text-input-label {
    .password-input-container {
        & .Form-textInput-label {
            position: absolute;
            color: #154754;
            font-weight: 600;
            font-size: 2.1vh;
            font-family: "Assistant", sans-serif;
            top: -3.5vh;
        }
    }
}
