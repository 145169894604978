@import "./_fonts.scss";

.home-page{
    background-color: #EEEEEE;
    // width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6vh;
    padding-bottom: 5vh;

    .home-container{
        width: 78vw;
    }
}