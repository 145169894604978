@import "./TextInputLabelMixin.scss";
@import "./fonts";

.add-instructor-pop-up-container {
    .add-instructor-pop-up {
        height: 86vh;
        width: 47.75vw;
        background-color: white;
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.5vh 0vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: Assistant, sans-serif;
        outline: none;

        @include text-input-label; // in order to change input label css
        width: 40vw;
        height: 70vh;
        background-color: white;
        border-radius: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .close-icon {
            margin-left: 78vh;
            cursor: pointer;
            margin-top: -10vh;
        }

        .title {
            color: #00638A;
            width: 55%;
            font-family: Heebo-Medium;
            font-size: 4.3vh;
            margin-bottom: 8vh;
            height: fit-content;
            text-align: right;
            margin-left: 3vw;
        }

        & .Form-submitButton {
            background-color: #00638A;
            width: calc(45vw * 0.55);
            font-size: 2.5vh;
            height: 4.5vh;
            align-self: center;
            color:white;
            font-family: "Assistant", sans-serif;
            font-weight: 600;
            box-shadow: none;
            border-radius: 7px;
            opacity: 0.7;
        }

        & .Form-submitButton:hover {
            background-color: #00638A;
            color: white;
            opacity: 1;
        }

        .success-alert {
            position: fixed;
            bottom: 24px;
            box-shadow: rgb(179, 173, 173) 0 1px 3px;
            border-radius: 5px;
        }
    }
}