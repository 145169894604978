@import "./fonts";
.password-input-container {
    // so it will be aligned with the title
    width: calc(45vw * 0.55);
    position: relative;
    align-self: center;
    direction: rtl;
    margin-bottom: 7vh;

    .text-input-container {
        & .Form-textInput {
            color: #154754;
            border: 1px solid rgba(219, 226, 230, 0.7);
            background-color: white;
            outline: none;
            height: 4.5vh;
            padding-right: 8px;
            font-size: 2vh;
            border-radius: 7px;
            font-weight: 600;
            font-family: Heebo-Regular;
        }

        & .Form-textInput.Form-error {
            border: 1px solid #ff002e;
            font-size: 1.6vh;
            color: #154754;
        }

        & .Form-error {
            font-size: 1.5vh;
            margin-inline: unset;
            font-family: Heebo-Regular;
            font-weight: 600;
            color: #ff002e;
        }

        & .Form-errorMsg-container {
            position: absolute;
            display: flex;
            align-items: center;
            height: 0vh;
            bottom: -3.5vh;
        }
    }

    .show-text-button {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 3%;
        height: 100%;
        cursor: pointer;
    }
}
