@import "./TextInputLabelMixin.scss";
@import "./fonts";

.sign-in-page-background-image {
    width: 100vw;
    height: 100vh;
    display: flex;
    font-family: "Assistant", sans-serif;
    flex-direction: row-reverse;
    .header {
        color: #00638A;
        width: 50%;
        font-size: 5.6vh;
        margin-bottom: 10vh;
        font-weight: 400;
        height: fit-content;
        text-align: right;
        font-family: Heebo-Medium;
    }
    .sign-in-form-container {
        @include text-input-label; // in order to change input label css
        width: 50%;
        height: 100%;
        background-color: white;
        border-radius: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        
        .password-input-container .Form-textInput-label{
            font-size: 2.4vh;
            font-family: Heebo-Regular;
        }

        & .Form-submitButton {
            background-color: #00638A;
            width: 11vw;
            font-size: 2.5vh;
            height: 6vh;
            align-self: right;
            margin-right: 6vw;
            color: white;
            font-family: "Assistant", sans-serif;
            font-weight: 600;
            box-shadow: none;
            border-radius: 7px;
            opacity: 0.7;
            margin-top: 8vh;
        }

        & .Form-submitButton:hover {
            background-color: #00638A;
            color: white;
            opacity: 1;
        }

        .container-under-the-button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 25vw;
            height: 4vh;
            margin-top: 23vh;
            position: absolute;
            
            .forget-password {
                font-family: Heebo-Regular;
                direction: rtl;
                text-decoration: underline;
                color: #154754;
                text-align: left;
                font-weight: 700;
                box-shadow: unset;
                font-size: 2.3vh;
                background-color: white;
                border: 2px solid white;
                cursor: pointer;
            }
        }

        .user-not-found {
            font-family: Heebo-Regular;
            color: red;
            font-size: 2.3vh;
        }
    }
    .forgot-password-form-container{
        @include text-input-label; // in order to change input label css
        width: 50%;
        height: 100%;
        background-color: white;
        border-radius: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        & .Form-submitButton {
            background-color: #00638A;
            width: 11vw;
            font-size: 2.5vh;
            height: 6vh;
            color: white;
            font-family: "Assistant", sans-serif;
            font-weight: 600;
            box-shadow: none;
            border-radius: 7px;
            opacity: 0.7;
            margin-right: 6.4vw;
            align-self:flex-end;
        }

        & .Form-submitButton:hover {
            background-color: #00638A;
            color: white;
            opacity: 1;
        }
        .go-back-container{
            margin-left: 22vw;
            margin-top: 2vh;
            .go-back{
                font-family: Heebo-Regular;
                text-decoration: underline;
                border:none;
                background-color: white;
                cursor: pointer;
                font-size: 2.5vh;

            }
        }
    }

    .image-container {
        width: 50%;
        height: 100%;
        background-color: #f4f4f4ba;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .logo-sign-in-page{
            width: 225px;
            height: 225px;
            margin-bottom: 5vh;
        }
        .welcome{
            font-family: Heebo-Regular;
            font-size: 7vh;
            direction: rtl;
            color:#1a688e;
            opacity: 0.9;
        }
    }
}