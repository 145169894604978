@import "./_fonts.scss";

.dates-statistics-container {
    display: flex;
    flex-direction: column;

    .dates-statistics-first-part {
        .tooltip-header{
            font-size: 1vw;
            padding-top: 1.8vh;
            font-family: Heebo-Medium;
        }
        .tooltip-content {
            font-size: 1vw;
            font-weight: 400;
            overflow-wrap: normal;
            font-family: Heebo-Regular;
        }
    }

    .dates-statistics-second-part{
        margin-top: 3vh;
        .recharts-legend-item-text {
            margin-right: 0.5vw;
            font-family: Heebo-regular;
        }
        .tooltip-content {
            font-size: 0.9vw;
            font-family: Heebo-Regular;
            margin-top: -1.5vh;
            // margin-left: 6vw;
        }
        .tooltip-header{
            font-size: 1vw;
            font-family: Heebo-Medium;
        }
    }

}