@import "./_fonts.scss";

.header-container {
    width: 77vw;
    margin-left: 1vw;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .header-text {
        text-align: right;
        font-family: Heebo-Regular;
        font-size: 5vh;
        color: #43555B;
    }

    .logo-container {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        align-items: center;

        .logo {
            width: 60px;
            height: 60px;
            margin-right: 2vw;
        }
    }

}