@import "./_fonts.scss";

.main-header{
    direction: rtl;
    font-family: Heebo-Bold;
    font-size: 3.5vh;
    color:#43555B;
    padding-right: 0.2vw;
    // margin-bottom: 1.3vh;
    margin-top: 2vh;
}
.little-header{
    direction: rtl;
    font-family: Heebo-Bold;
    font-size: 2.2vh;
    color:#43555B;
    padding-right: 0.2vw;
    margin-bottom: 2.2vh;
    margin-top: 2vh;
}