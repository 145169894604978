@import "./_fonts.scss";

.date-picker-container {
    display: flex;
    flex-direction: row-reverse;

    .input-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        height: 4vh;
        width: 10.5vw;

        .input-title {
            direction: rtl;
            text-align: right;
            font-family: Heebo-Regular;
            font-size: 2vh;
            overflow-wrap: normal;
        }

        .text-field-date {
            margin-right: 0.3vw;

            .MuiInputBase-input {
                font-size: 1.7vh;
                height: 0.6vh;
                font-family: Heebo-Regular;
                opacity: 0.8;
            }

            .css-i4bv87-MuiSvgIcon-root {
                width: 1vw;
                height: 3vh;
            }

            .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
                padding-right: 1vw;
                border: 1px solid #F3F3F3;
                background-color: white;
            }

            .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
                width: 7vw;

            }
        }
    }

    .filter-button {
        margin-right: 0.6vw;
        background-color: #448EAB;
        color: white;
        height: 4.8vh;
        font-family: Heebo-Regular;
        border-radius: 10%;
        top: 0.4vh
    }

}